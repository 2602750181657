import {
  CLIENT_SIDE_STRIPE_ERROR,
  CLIENT_SIDE_STRIPE_PAYMENT_INTENT_LAST_PAYMENT_ERROR,
  CLIENT_SIDE_STRIPE_PAYMENT_INTENT_STATUS_ERROR,
  CODE_LIMIT_NOT_BILLING_ADMIN,
  NOT_BILLING_ADMIN,
  PAGE_LIMIT_PILOT,
  PILOT_PLAN_IS_NOT_AVAILABLE_WHEN_SUBSCRIPTION_IS_ACTIVE,
  PLAN_IS_NOT_AVAILABLE
} from '@app/common/src/components/PlanPurchaseExperience/constants'
import { FlowError } from '@dtx-company/logger'

const BE_ERROR_CODES = [
  'error-customer-has-active-subscription',
  'error-invalid-address',
  'error-plan-already-queued',
  'error-plan-extras-unchanged',
  'error-subscription-has-past-due-invoice',
  'failed-creation',
  'failed-retrieval',
  'failed-update',
  'fraudulent',
  'generic_decline',
  'nil-db-record',
  'missing-payment-method',
  'not-instant-verification-payment-method'
]

const FE_ERROR_CODES = [
  CLIENT_SIDE_STRIPE_PAYMENT_INTENT_STATUS_ERROR,
  NOT_BILLING_ADMIN,
  CODE_LIMIT_NOT_BILLING_ADMIN,
  PILOT_PLAN_IS_NOT_AVAILABLE_WHEN_SUBSCRIPTION_IS_ACTIVE,
  PLAN_IS_NOT_AVAILABLE,
  PAGE_LIMIT_PILOT
]
export const DEFAULT_ERROR_MESSAGE = 'default-payment-error-message'
export const getPaymentErrorCode = (error: Error | FlowError): string => {
  switch (true) {
    case BE_ERROR_CODES.includes(error.message):
      return error.message
    case BE_ERROR_CODES.includes(error.name):
      return error.name
    case FE_ERROR_CODES.includes(error.name):
      return error.name
    case error.name === CLIENT_SIDE_STRIPE_ERROR:
    case error.name === CLIENT_SIDE_STRIPE_PAYMENT_INTENT_LAST_PAYMENT_ERROR:
      //stripe returns error message on the client side. User might close dialog window or additional validation failed
      return error.message
    case error instanceof FlowError && (error as FlowError).status === 404:
      return DEFAULT_ERROR_MESSAGE
    case error instanceof FlowError && (error as FlowError).status === 400:
    case error instanceof FlowError && (error as FlowError).status === 500:
      // stripe might fail on the BE side and return an error text. We want to show it to the user
      // Example: card 4000 0000 0000 0002, response 400: {"message":"Your card was declined."}
      return (error as FlowError).data?.message || DEFAULT_ERROR_MESSAGE

    default:
      return DEFAULT_ERROR_MESSAGE
  }
}

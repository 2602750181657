import { FlowcodeColorOptionType, FlowcodeThemeType } from '../types/createFlowcode.types'
import { FlowcodeThemes } from '@app/code/src/constants/flowcode-themes'
import {
  LandingPageDestination,
  LandingPageDestinationType,
  LandingPageFormValuesType
} from '@dtx-company/inter-app/src/types/flowcode'
import { TWITTER_LABEL } from '@app/code/src/components/molecules/destination-icon'
import { colors } from './colors'

export const LANDING_PAGE_DESTINATIONS: LandingPageDestinationType[] = [
  {
    label: 'Website',
    id: LandingPageDestination.URL,
    src: '/images/landing-page-destinations/website.svg',
    alt: 'website',
    inputLabel: 'Enter or Paste a link',
    placeholder: 'www.mywebsite.com'
  },
  {
    label: 'File',
    id: LandingPageDestination.FILE,
    src: '/images/landing-page-destinations/file.svg',
    alt: 'link to a File'
  },
  {
    label: 'YouTube',
    id: LandingPageDestination.YOUTUBE,
    src: '/images/landing-page-destinations/youtube.png',
    alt: 'youtube',
    inputLabel: 'Enter a link to your Youtube page or video',
    placeholder: 'www.youtube.com/c/WillSmith'
  },
  {
    label: 'Google Doc',
    id: LandingPageDestination.GOOGLE_DOC,
    src: '/logos/google.svg',
    alt: 'Google Doc',
    inputLabel: 'Enter or paste a link to your Google Doc',
    placeholder: 'https://docs.google.com/document'
  },
  {
    label: 'Facebook',
    id: LandingPageDestination.FACEBOOK,
    src: '/images/landing-page-destinations/facebook.svg',
    alt: 'facebook',
    inputLabel: 'Enter a link to your Facebook profile or post',
    placeholder: 'facebook.com/GetFlowcode'
  },
  {
    label: 'SMS',
    id: LandingPageDestination.SMS,
    src: '/images/landing-page-destinations/sms.svg',
    alt: 'sms'
  },
  {
    label: 'Email',
    id: LandingPageDestination.EMAIL,
    src: '/images/landing-page-destinations/email.svg',
    alt: 'email'
  },
  {
    label: 'WiFi',
    id: LandingPageDestination.WIFI,
    src: '/images/landing-page-destinations/wifi.svg',
    alt: 'wifi'
  },
  {
    label: 'Venmo',
    id: LandingPageDestination.VENMO,
    src: '/images/landing-page-destinations/venmo.svg',
    alt: 'venmo'
  },
  {
    label: 'Phone call',
    id: LandingPageDestination.PHONE,
    src: '/images/landing-page-destinations/phone.svg',
    alt: 'phone call'
  },
  {
    label: 'LinkedIn',
    id: LandingPageDestination.LINKEDIN,
    src: '/images/landing-page-destinations/linkedin.svg',
    alt: 'linkedin',
    inputLabel: 'Enter a link to your LinkedIn profile or post',
    placeholder: 'www.linkedin.com/in/erik-hanson/'
  },
  {
    label: 'TikTok',
    id: LandingPageDestination.TIKTOK,
    src: '/images/landing-page-destinations/tiktok.svg',
    alt: 'tiktok',
    inputLabel: 'Enter a link to your TikTok profile or post',
    placeholder: 'tiktok.com/@myusername/'
  },
  {
    label: 'Snapchat',
    id: LandingPageDestination.SNAPCHAT,
    src: '/images/landing-page-destinations/snapchat.svg',
    alt: 'snapchat',
    inputLabel: 'Enter a link to your Snapchat username',
    placeholder: 'snapchat.com/add/username'
  },
  {
    label: TWITTER_LABEL,
    id: LandingPageDestination.TWITTER,
    src: '/icons/social-media/twitter.svg',
    alt: 'twitter',
    inputLabel: `Enter a link to your ${TWITTER_LABEL} profile or tweet`,
    placeholder: 'https://twitter.com/myusername'
  },
  {
    label: 'Spotify',
    id: LandingPageDestination.SPOTIFY,
    src: '/images/landing-page-destinations/spotify.svg',
    alt: 'spotify',
    inputLabel: 'Enter a link to your Spotify profile or playlist',
    placeholder: 'open.spotify.com/genre/rnb-page'
  },
  {
    label: 'Flowpage',
    id: LandingPageDestination.FLOWPAGE,
    src: '/icons/flowpage-logo-black.svg',
    alt: 'Flowpage'
  },
  {
    label: 'Scan to Own',
    id: LandingPageDestination.SCAN_TO_OWN,
    src: '/images/landing-page-destinations/flowcode.svg',
    alt: 'scanToOwn'
  }
]

const { builder } = colors

export const LOGGED_OUT_FLOWCODE_COLOR_OPTIONS: FlowcodeColorOptionType[] = [
  {
    label: 'Black',
    color: builder.black,
    id: 'black',
    defaultOnly: false,
    index: 0
  },
  {
    label: 'Grey',
    color: builder.greyLight,
    darkColor: builder.greyDark,
    id: 'grey',
    defaultOnly: true,
    index: 2
  },
  {
    label: 'Red',
    color: builder.redLight,
    darkColor: builder.redDark,
    id: 'red',
    defaultOnly: true,
    index: 3
  },
  {
    label: 'Magenta',
    color: builder.magentaLight,
    darkColor: builder.magentaDark,
    id: 'magenta',
    defaultOnly: true,
    index: 7
  },
  {
    label: 'Pink',
    color: builder.pinkLight,
    darkColor: builder.pinkDark,
    id: 'pink',
    defaultOnly: true,
    index: 9
  },
  {
    label: 'Purple',
    color: builder.purpleDark,
    darkColor: builder.purpleLight,
    id: 'purple',
    defaultOnly: true,
    index: 12
  },
  {
    label: 'Navy',
    color: builder.navyDark,
    darkColor: builder.navyLight,
    id: 'navy',
    defaultOnly: true,
    index: 14
  },
  {
    label: 'Blue',
    color: builder.blueLight,
    darkColor: builder.blueDark,
    id: 'blue',
    defaultOnly: true,
    index: 16
  },
  {
    label: 'Turquoise',
    color: builder.turquoiseLight,
    darkColor: builder.turquoiseDark,
    id: 'turquoise',
    defaultOnly: true,
    index: 19
  },
  {
    label: 'Jade',
    color: builder.jadeLight,
    darkColor: builder.jadeDark,
    id: 'jade',
    defaultOnly: true,
    index: 20
  },
  // {
  //   label: 'Lemon',
  //   color: builder.lemonLight,
  //   darkColor: builder.lemonDark,
  //   id: 'lemon',
  //   defaultOnly: true,
  //   index: 24
  // },
  // {
  //   label: 'Yellow',
  //   color: builder.yellowLight,
  //   darkColor: builder.yellowDark,
  //   id: 'yellow',
  //   defaultOnly: true,
  //   index: 26
  // },
  {
    label: 'Pumpkin',
    color: builder.pumpkinLight,
    darkColor: builder.pumpkinDark,
    id: 'pumpkin',
    defaultOnly: true,
    index: 30
  },
  {
    label: 'Brown',
    color: builder.brownLight,
    darkColor: builder.brownDark,
    id: 'brown',
    defaultOnly: true,
    index: 31
  }
]

export const FLOWCODE_THEME_OPTIONS: FlowcodeThemeType[] = [
  {
    dark: false,
    inverted: false,
    id: FlowcodeThemes.LightStandard,
    label: 'light_standard',
    defaultOnly: false,
    name: 'Standard'
  },
  {
    dark: true,
    inverted: false,
    id: FlowcodeThemes.DarkStandard,
    label: 'dark_standard',
    defaultOnly: true,
    name: 'Dark'
  }
]

/** @deprecated - This is used on the edit design page for backwards compatibility. - DF */
export const FLOWCODE_THEME_OPTIONS_WITH_INVERTED_OPTIONS: FlowcodeThemeType[] = [
  {
    dark: false,
    inverted: false,
    id: FlowcodeThemes.LightStandard,
    label: 'light_standard',
    defaultOnly: false,
    name: 'Standard'
  },
  {
    dark: false,
    inverted: true,
    id: FlowcodeThemes.LightInverted,
    label: 'light_inverted',
    defaultOnly: true,
    name: 'Standard inverted'
  },
  {
    dark: true,
    inverted: false,
    id: FlowcodeThemes.DarkStandard,
    label: 'dark_standard',
    defaultOnly: true,
    name: 'Dark'
  },
  {
    dark: true,
    inverted: true,
    id: FlowcodeThemes.DarkInverted,
    label: 'dark_inverted',
    defaultOnly: true,
    name: 'Dark inverted'
  }
]

export const LANDING_PAGE_FORM_INITIAL_VALUES: LandingPageFormValuesType = {
  link: '',
  sms: {
    message: '',
    phoneNumber: ''
  },
  phone: '',
  email: {
    email: '',
    subject: '',
    body: ''
  },
  venmo: {
    amount: undefined,
    recipients: '@',
    note: ''
  },
  file: null,
  event: {
    name: '',
    startTime: '',
    url: '',
    location: '',
    endTime: ''
  },
  wifi: {
    ssid: '',
    authenticationType: 'nopass',
    password: '',
    hidden: ''
  },
  vcard: {
    first_name: undefined,
    last_name: undefined,
    company: undefined,
    title: undefined,
    email: undefined,
    phone: undefined,
    phone_country_code: undefined,
    work_phone: undefined,
    work_phone_country_code: undefined,
    website: undefined,
    street: undefined,
    street2: undefined,
    city: undefined,
    state: undefined,
    zipcode: undefined,
    country: undefined
  }
}

export const CONTACT_SUPPORT_EMAIL = `mailto:support@flowcode.com`
export const MAX_INSTAGRAM_INPUT_LENGTH = 28

import {
  ContactUsLockModalVariants,
  setContactUsLockModalVariant
} from '@dtx-company/inter-app/src/redux/slices/contactUsLockModalSlice'
import { IthacaOperationName } from '@dtx-company/true-common/src/types/operations'
import { RootState } from '@dtx-company/inter-app/src/redux/types'
import { fireAnalyticsEvent } from '@dtx-company/inter-app/src/event-tracking/helpers/fireAnalyticsEvent'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export interface UseContactUsLockModalReturnType {
  modalOpen: boolean
  onClose: () => void
  onOpen: (variant: ContactUsLockModalVariants) => void
  contactUsLockModalVariant?: ContactUsLockModalVariants
}

export interface UseOpenDemoModalArgs {
  useContactUsLockModalVariant: ContactUsLockModalVariants
  ithacaOperationName: IthacaOperationName
  trackingCall?: () => void
  gatedAction: () => void
}

export interface OpenDemoModalReturn {
  handleProtectedActionClick: () => void
  isGateOpen: boolean
}

export const useContactUsLockModal = (): UseContactUsLockModalReturnType => {
  const dispatch = useDispatch()
  const { contactUsLockModalVariant } = useSelector(
    (state: RootState) => state.revenueReducer.contactUsLockModal
  )
  const modalOpen = Boolean(contactUsLockModalVariant)

  const onClose = (): void => {
    fireAnalyticsEvent('contact_us_lock_modal_closed', { variant: contactUsLockModalVariant })
    dispatch(setContactUsLockModalVariant())
  }

  const onOpen = useCallback(
    (variant: ContactUsLockModalVariants): void => {
      fireAnalyticsEvent('contact_us_lock_modal_opened', { variant })
      dispatch(setContactUsLockModalVariant(variant))
    },
    [dispatch]
  )
  return { onClose, onOpen, modalOpen, contactUsLockModalVariant }
}

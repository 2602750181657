import { TranslationQuery } from 'next-translate'
import { getPaymentErrorCode } from '@app/common/src/components/PlanPurchaseExperience/getPaymentErrorCode'
import { sendErrorNotification } from '@dtx-company/inter-app/src/utils/notifications'
import getT from 'next-translate/getT'
import useTranslation from 'next-translate/useTranslation'

export const usePaymentMethodError = (): {
  sendPaymentMethodErrorMessage: (error: Error, query?: TranslationQuery) => void
  getErrorMessage: (error: Error, query?: TranslationQuery) => string
} => {
  const { t } = useTranslation('payment-messages')
  const sendPaymentMethodErrorMessage = (error: Error, query?: TranslationQuery): void => {
    const errorCode = getPaymentErrorCode(error)
    sendErrorNotification(t(errorCode, query))
  }
  const getErrorMessage = (error: Error, query?: TranslationQuery): string => {
    const errorCode = getPaymentErrorCode(error)
    return t(errorCode, query)
  }
  return { sendPaymentMethodErrorMessage, getErrorMessage }
}

export const getEnPaymentMessage = async (error: Error): Promise<string> => {
  const errorCode = getPaymentErrorCode(error)
  const t = await getT('en-US', 'payment-messages')
  return t(errorCode)
}

import { FcGeneratorOptions, FcShape, FcText } from '@dtx-company/flowcode-generator-browser/src'

/**
 * Apply frame and rim text
 */

export const getFrameOptions = (
  topRimText = '',
  bottomRimText = '',
  backgroundColor = '#000000',
  selectedShape = FcShape.CIRCLE
): FcGeneratorOptions => {
  const containerRimText: FcText[] = []
  const fontProps = {
    font: 'centra_light',
    fontSize: selectedShape === FcShape.CIRCLE ? 15 : 18,
    fontColor: '#ffffff'
  }

  if (topRimText) {
    containerRimText.push({
      ...fontProps,
      text: topRimText,
      position: { property: 6, offsetPercentage: 0 }
    })
  }

  if (bottomRimText) {
    containerRimText.push({
      ...fontProps,
      text: bottomRimText,
      position: { property: 7, offsetPercentage: 0 }
    })
  }

  return {
    data: 'https://flowcode.com/p/eiAZkKvIM',
    radiusMask: 89,
    containerRimWidth: 15,
    containerRimBorderSize: 8,
    containerRimBackgroundColor: backgroundColor,
    ...(selectedShape === FcShape.CIRCLE && {
      containerRimBorderSize: 1,
      containerRimWidth: 22,
      containerBorderSize: 1,
      containerBorderColor: '#FFF'
    }),
    dataGridPercentageSize: 70,
    containerRimText,
    ...(selectedShape === FcShape.SQUARE && {
      dataGridPercentageSize: 94,
      containerShape: FcShape.SQUARE,
      containerOuterPatternRandomSizes: '0'
    })
  }
}

export const PlanPurchaseExperienceMainContentMinWidth = 400
export const PlanPurchaseExperienceMainContentMaxWidth = 596
export const PlanPurchaseExperiencePromoContentFullWidth = 376

export const PlanPurchaseExperienceNarrowFieldMaxWidth = 448

export const CLIENT_SIDE_STRIPE_ERROR = 'StripePaymentFailedError'
export const CLIENT_SIDE_STRIPE_PAYMENT_INTENT_LAST_PAYMENT_ERROR = 'PaymentIntentLastPaymentError'
export const CLIENT_SIDE_STRIPE_PAYMENT_INTENT_STATUS_ERROR = 'PaymentIntentStatusError'
export const PILOT_PLAN_IS_NOT_AVAILABLE_WHEN_SUBSCRIPTION_IS_ACTIVE =
  'PilotPlanIsNotAvailableWhenSubscriptionIsActive'
export const PLAN_IS_NOT_AVAILABLE = 'PlanIsNotAvailable'
export const NOT_BILLING_ADMIN = 'not-billing-admin'
export const CODE_LIMIT_NOT_BILLING_ADMIN = 'code-limit-not-billing-admin'
export const PAGE_LIMIT_PILOT = 'page-limit-pilot'

export const refreshStripePaymentElementForPaymentTypes = ['google_pay', 'apple_pay']

export const displayPaymentMethodAddressFields = ['cashapp', 'us_bank_account']

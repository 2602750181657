import { CODE_LIMIT_NOT_BILLING_ADMIN } from '@app/common/src/components/PlanPurchaseExperience/constants'
import { ClientSideError, ClientSideErrorType, logger } from '@dtx-company/logger'
import { sendErrorNotification } from '@dtx-company/inter-app/src/utils/notifications'
import { useCallback } from 'react'
import { useIsBillingAdmin } from '@dtx-company/inter-app/src/hooks/useIsBillingAdmin/useIsBillingAdmin'
import { usePaymentMethodError } from '@app/common/src/components/PlanPurchaseExperience/usePaymentMethodError'

export const useOpenFlowcodeLimitModal = (): {
  validateAndOpenFlowcodeLimitModal: (onOpenModal: () => void) => void
} => {
  const isBillingAdmin = useIsBillingAdmin()
  const { getErrorMessage } = usePaymentMethodError()
  const validateAndOpenFlowcodeLimitModal = useCallback(
    (onOpenModel: () => void): void => {
      if (isBillingAdmin) {
        onOpenModel()
      } else {
        const error = new ClientSideError({
          name: CODE_LIMIT_NOT_BILLING_ADMIN,
          message: CODE_LIMIT_NOT_BILLING_ADMIN,
          type: ClientSideErrorType.ClientSideValidationError
        })
        logger.logError(error, { technicalArea: 'monetization', team: 'monetization' })
        sendErrorNotification(getErrorMessage(error))
      }
    },
    [isBillingAdmin, getErrorMessage]
  )

  return {
    validateAndOpenFlowcodeLimitModal
  }
}

import { DEFAULT_FLOWCODE_OPTIONS } from '../../../../LoggedOutCodeGenerator.constants'
import {
  FcColorBlack,
  FcPosition,
  FcPositionProperty,
  FcShape,
  FcText
} from '@dtx-company/flowcode-generator-browser/src'

/**
 * Create container text. Changes based on frame and shape
 */

export const createContainerText = (
  shape: FcShape,
  showFrame: boolean,
  fontColor = FcColorBlack
): FcText[] => {
  let containerTextFontSize = DEFAULT_FLOWCODE_OPTIONS.containerText[0].fontSize
  let containerTextOffset = DEFAULT_FLOWCODE_OPTIONS.containerText[0].position.offsetPercentage

  if (showFrame) {
    if (shape === FcShape.SQUARE) {
      containerTextFontSize = 8.5
      containerTextOffset = 2.8
    } else {
      containerTextFontSize = 8
      containerTextOffset = -0.2
    }
  } else {
    if (shape === FcShape.CIRCLE) {
      containerTextFontSize = 8.5
      containerTextOffset = 2.8
    }
  }

  return [
    new FcText(
      'PRIVACY.FLOWCODE.COM',
      'inter_medium',
      containerTextFontSize,
      fontColor,
      new FcPosition(FcPositionProperty.BOTTOM, containerTextOffset)
    )
  ]
}

import { FcGeneratorOptions } from '@dtx-company/flowcode-generator-types/src'

export type Options = 'qrrotate' | 'positionElementBottomLeft' | 'positionElementTopRight'

export const getRotatedOptions = (
  rotate: boolean,
  options?: FcGeneratorOptions
): Pick<FcGeneratorOptions, Options> => {
  return {
    qrrotate: rotate,
    positionElementBottomLeft: options?.positionElementTopRight,
    positionElementTopRight: options?.positionElementBottomLeft
  }
}

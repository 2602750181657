import * as Yup from 'yup'
import { AuthenticationFormProps } from './AuthenticationForm.types'
import { MIN_PASSWORD_LENGTH } from '@app/code/src/constants/flowcode'
import { PlanType } from '@dtx-company/true-common/src/types/planType'
import { UpsellModalProps } from '@app/upsell-modals/src/UpsellModal/UpsellModalProps'
import { useAuthCache } from '@app/authentication/AuthCache/hooks'
import { useAuthState } from '../../hooks/useAuthState'
import { useBillingPlanType } from '@app/common/src/hooks/useBillingPlanType/useBillingPlanType'
import {
  useBuildLoggedOutGeneratorCreateCodePayload,
  useLoggedOutGeneratorCreateAndDownloadCode
} from '@app/code/src/components/LoggedOutCodeGeneratorV2/hooks/useLoggedOutGeneratorCreateCode.hooks'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useContactUsLockModal } from '@app/common/src/hooks/useContactUsLockModal/useContactUsLockModal'
import { useIsAccountPaused } from '../../hooks/useIsAccountPaused'
import { useOpenFlowcodeLimitModal } from '@app/upsell-modals/src/FlowcodeLimitUpsellModal/useOpenFlowcodeLimitModal'
import { useRedirectToPausedAccountPage } from '../../hooks/useRedirectToPausedAccountPage'
import useTranslation from 'next-translate/useTranslation'

// At least one lowercase letter, one uppercase letter, one number, one special character, and at least min pass length characters
export const passwordRegex = new RegExp(
  `^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-!"#$%&'()*+,./:;<=>?@^_{|}~]).{${MIN_PASSWORD_LENGTH},}$`
)

export const useAuthenticationFieldsValidationSchema = ({
  isSignIn,
  invitationDomain,
  mode,
  isUS
}: {
  invitationDomain: string | undefined
  mode: 'sso' | 'emailAndPassword'
  isUS?: boolean
} & Pick<AuthenticationFormProps, 'isSignIn'>): Yup.ObjectSchema<
  object & {
    email: string
    password: string
    terms: boolean
  }
> => {
  const { t } = useTranslation('authentication')
  return useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .required(t('Email.validation.required'))
          .email(t('Email.validation.invalid'))
          .test(
            'isValidSSOEmail',
            t('Email.validation.invalidSSOEmail', { invitationDomain }),
            (email: string) => {
              if (invitationDomain)
                return email.toLowerCase().endsWith('@' + invitationDomain?.toLowerCase())
              else return true
            }
          ),
        password: Yup.string()
          .test('required', t('Password.validation.required'), (password: string) => {
            if (mode === 'emailAndPassword') return Boolean(password)
            else return true
          })
          .test(
            'minimumRequirements',
            t('Password.validation.minimum', { minimum: MIN_PASSWORD_LENGTH }),
            (password: string) => {
              if (mode === 'emailAndPassword' && !isSignIn) {
                return passwordRegex.test(password)
              } else return true
            }
          ),
        terms: Yup.boolean().test(
          'required',
          t('SignUpPage.errors.termsCheckbox'),
          (terms: boolean) => {
            if (isSignIn || isUS || mode === 'sso') return true
            else return Boolean(terms)
          }
        )
      }),
    [isSignIn, isUS, mode, invitationDomain, t]
  )
}

export const useAuthFormSubmitButtonLabel = ({
  isSignIn,
  mode,
  isFreeQrGeneratorPage
}: {
  isFreeQrGeneratorPage: boolean
  isSignIn?: boolean
  mode: 'sso' | 'emailAndPassword'
}): string => {
  const { t } = useTranslation('authentication')

  if (isFreeQrGeneratorPage) {
    return t('FreeQrCodeGeneratorPage.signUpButtonLabel')
  } else {
    return mode === 'sso'
      ? t('SignInPage.ssoButtonLabel')
      : t('AuthFormSubmitButtonLabel', {
          cta: isSignIn ? 'Sign in' : 'Sign up'
        })
  }
}

export const useCodeGenerator = (): {
  upgradeModalOpen: boolean
  contactUsModalOpen: boolean
  onUpgradeModalClose: (reason: 'success' | 'dismiss') => void
  handleGeneratorCodeCreation: () => Promise<void>
  setLocgFormValuesInAuthCache: () => Promise<void>
} => {
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false)
  const [upgradeModalShouldBeOpened, setUpgradeModalShouldBeOpened] = useState(false)
  const { onOpen: openContactUsModal, modalOpen: contactUsModalOpen } = useContactUsLockModal()
  const billingPlanType = useBillingPlanType()

  const { jwt } = useAuthState()
  const loggedOutGeneratorCreateCodeAndRedirect = useLoggedOutGeneratorCreateAndDownloadCode()
  const createCodeArgs = useBuildLoggedOutGeneratorCreateCodePayload()
  const authCache = useAuthCache()

  const redirectToPausedAccountPage = useRedirectToPausedAccountPage()

  const isAccountPaused = useIsAccountPaused()
  const { validateAndOpenFlowcodeLimitModal } = useOpenFlowcodeLimitModal()

  const openUpgradeModal = useCallback(() => {
    if (billingPlanType && [PlanType.PRO_PLUS_ORG, PlanType.PRO_FLEX].includes(billingPlanType)) {
      openContactUsModal('codeLimit')
    } else {
      validateAndOpenFlowcodeLimitModal(() => setUpgradeModalOpen(true))
    }
  }, [validateAndOpenFlowcodeLimitModal, billingPlanType, openContactUsModal])

  // immediately after sign in if code creation fails jwt token is not updated yet.
  // jwt is a react hook value, it is updated on next render cycle. We have to wait until jwt is updated
  // before validationg is user a billing admin or not and lock modal can be opened
  useEffect(() => {
    if (upgradeModalShouldBeOpened && jwt) {
      openUpgradeModal()
      setUpgradeModalShouldBeOpened(false)
    }
  }, [jwt, openUpgradeModal, upgradeModalShouldBeOpened])

  const onUpgradeModalClose = useCallback<UpsellModalProps['onClose']>(
    async reason => {
      setUpgradeModalOpen(false)
      if (reason === 'success') {
        await loggedOutGeneratorCreateCodeAndRedirect(undefined, () =>
          setUpgradeModalShouldBeOpened(true)
        )
      }
    },
    [loggedOutGeneratorCreateCodeAndRedirect]
  )

  const handleGeneratorCodeCreation = async (): Promise<void> => {
    if (isAccountPaused) redirectToPausedAccountPage()
    await loggedOutGeneratorCreateCodeAndRedirect(undefined, () =>
      setUpgradeModalShouldBeOpened(true)
    )
  }

  const setLocgFormValuesInAuthCache = async (): Promise<void> => {
    if (authCache) {
      await authCache.locgFormValues.set(createCodeArgs)
    }
  }

  return {
    upgradeModalOpen,
    contactUsModalOpen,
    onUpgradeModalClose,
    handleGeneratorCodeCreation,
    setLocgFormValuesInAuthCache
  }
}
